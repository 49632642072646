var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"langcategoryeMasterValidation"},[_c('b-form',[_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('feather-icon',{attrs:{"icon":"SkipBackIcon"}}),_vm._v(" "+_vm._s(_vm.$t("Common.Back"))+" ")],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Language Category Master List.LangCategory Name')}},[_c('validation-provider',{attrs:{"name":"LangCategory Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"text","placeholder":_vm.$t('Language Category Master List.LangCategory Name'),"disabled":!_vm.$can('write', 'masters')},model:{value:(_vm.form.langcategory_name),callback:function ($$v) {_vm.$set(_vm.form, "langcategory_name", $$v)},expression:"form.langcategory_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Language Category Master List.LangCategory Code')}},[_c('validation-provider',{attrs:{"name":"LangCategory Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"text","placeholder":_vm.$t('Language Category Master List.LangCategory Code'),"disabled":!_vm.$can('write', 'masters')},model:{value:(_vm.form.langcategory_code),callback:function ($$v) {_vm.$set(_vm.form, "langcategory_code", $$v)},expression:"form.langcategory_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Language Category Master List.Language Name')}},[_c('validation-provider',{attrs:{"name":"Language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (langcategory) { return langcategory.language_id; },"label":"language_name","placeholder":_vm.$t('Language Category Master List.Language Name'),"disabled":!_vm.$can('write', 'masters'),"state":errors.length > 0 ? false : null,"options":_vm.$store.getters['masters/getAllLanguage']},model:{value:(_vm.form.langcategory_lang_id),callback:function ($$v) {_vm.$set(_vm.form, "langcategory_lang_id", $$v)},expression:"form.langcategory_lang_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Language Category Master List.LangCategory Description')}},[_c('validation-provider',{attrs:{"name":"LangCategory Description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"type":"text","placeholder":_vm.$t('Language Category Master List.LangCategory Description'),"disabled":!_vm.$can('write', 'masters'),"rows":"3"},model:{value:(_vm.form.langcategory_desc),callback:function ($$v) {_vm.$set(_vm.form, "langcategory_desc", $$v)},expression:"form.langcategory_desc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.saveLangcategory.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("Common.Save"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }